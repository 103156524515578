<template>
  <Show v-model="selectedBoard"></Show>
  <BasePending v-if="pending" />
  <Accordion v-else :activeIndex="[0]" :multiple="true">
    <!--    <AccordionTab header="Nouvel inventaire">-->
    <!--      <CreateBoard></CreateBoard>-->
    <!--    </AccordionTab>-->
    <AccordionTab header="Inventaires">
      <DataTable
        ref="dt"
        rowHover
        stripedRows
        v-model:selection="selectedBoards"
        class="p-datatable-sm"
        :value="boards"
        :paginator="true"
        :rows="rows"
        dataKey="id"
        v-model:filters="filters"
        filterDisplay="row"
        :loading="pending"
      >
        <template #empty> Aucun article trouvé. </template>
        <template #loading> Chargement des données. </template>
        <template #footer>
          <div class="flex align-items-center justify-content-end">
            <label class="mr-2">Afficher:</label>
            <Dropdown :options="[10, 25, 50, 100]" v-model="rows" />
          </div>
        </template>
        <Column
          selectionMode="multiple"
          class="text-center"
          style="width: 48px"
        />
        <Column
          :showFilterMenu="false"
          filterField="center"
          header="Etablissement"
        >
          <template #filter="{ filterModel, filterCallback }">
            <MultiSelect
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="centers"
              optionValue="id"
              optionLabel="name"
              placeholder="Tous"
              class="p-column-filter"
              display="chip"
              filter
            >
              <template #option="slotProps">
                <div class="p-multiselect-representative-option">
                  <span>{{ slotProps.option.name }}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
          <template #body="{ data }">
            {{ docs[data.center].name }}
          </template>
        </Column>
        <Column :showFilterMenu="false" filterField="name" header="Nom">
          <template #filter="{ filterModel, filterCallback }">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                type="text"
                v-model="filterModel.value"
                @change="filterCallback()"
                placeholder="Rechercher par nom"
              />
            </span>
          </template>
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>
        <Column :showFilterMenu="false" filterField="period" header="Période">
          <template #filter="{ filterModel, filterCallback }">
            <Calendar
              v-model="filterModel.value"
              @date-select="filterCallback()"
              :showIcon="true"
              :manualInput="false"
              :showButtonBar="true"
            />
          </template>
          <template #body="{ data }">
            {{ format(parseJSON(data.period), "dd/MM/yyyy") }}
          </template>
        </Column>
        <Column filterField="status" header="Status" :showFilterMenu="false">
          <template #filter="{ filterModel, filterCallback }">
            <MultiSelect
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="statusArray"
              optionValue="id"
              optionLabel="label"
              placeholder="Tous"
              class="p-column-filter"
              display="chip"
            >
              <template #option="slotProps">
                <div class="p-multiselect-representative-option">
                  <span>{{ slotProps.option.label }}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
          <template #body="{ data }">
            <span :class="`text-${status[data.status].color}`"
              ><i :class="status[data.status].icon" />
              {{ status[data.status].label }}</span
            >
          </template>
        </Column>
        <Column header="Valorisation" :showFilterMenu="false">
          <template #body="{ data }">
            <strong v-formatCurrency="calcVal(data.rows)"></strong>
          </template>
        </Column>
        <Column :showFilterMenu="false" class="justify-content-end text-right">
          <template #filter>
            <Button
              icon="pi pi-trash"
              class="p-button-sm"
              :disabled="!selectedBoards.length"
              :class="
                !selectedBoards.length
                  ? 'p-button-secondary p-button-text'
                  : 'p-button-danger p-button-outlined'
              "
              @click="onRemoveSelected"
            />
          </template>
          <template #body="{ data }">
            <Button
              v-if="data.comments"
              icon="fa fa-comment-alt"
              class="p-button-text p-button-info"
              v-tooltip.left="data.comments"
            />
            <Button
              icon="pi pi-download"
              class="p-button-text p-button-sm p-button-primary"
              v-if="data.status === 'sent'"
              @click="exportToCsv(data)"
            />
            <Button
              icon="pi pi-eye"
              class="p-button-text p-button-sm p-button-primary"
              v-if="data.status === 'sent'"
              @click="selectedBoard = data"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-text p-button-sm"
              :class="
                selectedBoards.length ? 'p-button-secondary' : 'p-button-danger'
              "
              :disabled="selectedBoards.length"
              @click="onRemove($event, data)"
            />
          </template>
        </Column>
      </DataTable>
    </AccordionTab>
  </Accordion>
</template>

<script>
import { ref, computed, defineComponent } from "vue";
import { FilterMatchMode } from "primevue/api";
import useDatabase from "../../composables/useDatabase";
import { parseJSON, format } from "date-fns";
import { projectDatabase } from "@/firebase/config";
import { Parser } from "json2csv";
import { saveAs } from "file-saver";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { forEach } from "lodash";

import Show from "./_Show";
// import CreateBoard from "./_Create";

export default defineComponent({
  components: { Show },
  setup() {
    const toast = useToast();
    const deleteConfirm = useConfirm();

    const selectedBoard = ref(undefined);

    const pending = computed(() => {
      return !!(boardsPending.value || centersPending.value);
    });

    const { docs: articles } = useDatabase("mercurial/articles").mapAll();

    const calcVal = (data) => {
      const result = [];
      forEach(data, (value, key) => {
        if (articles.value[key]?.rates?.t0) {
          result.push(Math.round(articles.value[key]?.rates.t0 * value.total));
        }
      });
      if (result.length > 0) {
        return result.reduce((sum, n) => {
          return sum + n;
        });
      }
      return 0;
    };

    const { getAll } = useDatabase("boards");
    const { docs: boards, pending: boardsPending } = getAll();

    const { docs, pending: centersPending } = useDatabase("centers").mapAll();
    const centers = computed(() => {
      let array = [];
      if (docs.value) {
        Object.entries(docs.value).forEach((center) => {
          array.push({ id: center[0], ...center[1] });
        });
      }
      return array;
    });

    const status = ref({
      new: { label: "Nouveau", icon: "fa fa-certificate", color: "warning" },
      inprogress: {
        label: "En cours",
        icon: "fa fa-pen-alt",
        color: "secondary",
      },
      validated: {
        label: "En attente",
        icon: "fa fa-hourglass-half",
        color: "info",
      },
      sent: { label: "Envoyé", icon: "fa fa-paper-plane", color: "primary" },
    });
    const statusArray = computed(() => {
      let array = [];
      Object.entries(status.value).forEach((s) => {
        array.push({ id: s[0], ...s[1] });
      });
      return array;
    });

    const rows = ref(10);
    const filters = ref({
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      period: { value: null, matchMode: FilterMatchMode.DATE_IS },
      center: { value: null, matchMode: FilterMatchMode.CONTAINS },
      status: { value: null, matchMode: FilterMatchMode.IN },
    });

    // TODO: convert Buffer to ArrayBuffer
    // function a2ab(s) {
    //   return new Uint8Array(s).buffer;
    // }
    //
    // const exportToExcel = async () => {
    //   const exportToExcel = projectFunctions.httpsCallable("exportToExcel");
    //   const res = await exportToExcel();
    //   let blob = new Blob([a2ab(res.data)], {
    //     type: "application/vnd.ms-excel;charset=utf-8",
    //   });
    //   saveAs(blob, "test.xlsx");
    // };

    const exportToCsv = async (data) => {
      try {
        const rows = await projectDatabase.ref(`/boards/${data.id}/rows`).get();
        const articles = await projectDatabase.ref("mercurial/articles").get();

        let fields = [];
        Object.entries(rows.val()).forEach((row) => {
          if (articles.val()[row[0]]) {
            fields.push({
              code: row[0].toString(),
              name: articles.val()[row[0]].name,
              cond: Number(articles.val()[row[0]].capacity),
              total: Number(row[1].total),
              t0: articles.val()[row[0]].rates
                ? Math.round(
                    Number(articles.val()[row[0]].rates.t0 * row[1].total) * 100
                  ) / 100
                : 0,
              t1: articles.val()[row[0]].rates
                ? Math.round(
                    Number(articles.val()[row[0]].rates.t1 * row[1].total) * 100
                  ) / 100
                : 0,
            });
          }
        });

        const parser = new Parser();
        const csv = parser.parse(fields);
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(
          blob,
          `${format(parseJSON(data.period), "dd-MM-Y")}__${
            docs.value[data.center].name
          }.csv`
        );
      } catch (e) {
        console.log(e);
      }
    };

    const selectedBoards = ref([]);

    const onRemoveSelected = (event) => {
      deleteConfirm.require({
        target: event.currentTarget,
        message: "Êtes-vous certain de vouloir supprimer ces inventaires?",
        acceptClass: "p-button-danger",
        acceptLabel: "Supprimer",
        rejectClass: "p-button-text p-button-secondary",
        rejectLabel: "Retour",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          let updates = {};
          selectedBoards.value.forEach((board) => {
            // if (board.status !== "sent") {}
            updates[`boards/${board.id}`] = null;
          });
          try {
            await projectDatabase.ref().update(updates);
            selectedBoards.value = [];
            toast.add({
              severity: "success",
              detail: "Inventaires supprimés",
              life: 3000,
            });
          } catch (e) {
            console.log(e.message);
          }
        },
      });
    };

    const onRemove = (event, data) => {
      deleteConfirm.require({
        target: event.currentTarget,
        message: "Êtes-vous certain de vouloir supprimer cet inventaire?",
        acceptClass: "p-button-danger",
        acceptLabel: "Supprimer",
        rejectClass: "p-button-text p-button-secondary",
        rejectLabel: "Retour",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          try {
            await projectDatabase.ref(`boards/${data.id}`).remove();
            toast.add({
              severity: "success",
              detail: "Inventaire supprimé",
              life: 3000,
            });
          } catch (e) {
            console.log(e.message);
          }
        },
      });
    };

    return {
      selectedBoards,
      selectedBoard,
      parseJSON,
      format,
      boards,
      docs,
      centers,
      pending,
      filters,
      rows,
      status,
      statusArray,
      exportToCsv,
      onRemove,
      onRemoveSelected,
      calcVal,
      // exportToExcel,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.p-card.table) {
  .p-card-body {
    padding: 0;
  }
  .p-card-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0.75rem;
  }
}

::v-deep(.p-accordion-tab) {
  .p-accordion-content {
    padding: 0;
  }
}
</style>
