<template>
  <Dialog
    v-model:visible="state"
    class="table"
    modal
    :showHeader="true"
    :maximizable="true"
    :loading="pending"
    style="width: 700px"
  >
    <template #header>
      <div class="flex flex-column">
        <h2>{{ centers[board.center].name }}</h2>
        <h3 class="display-4">
          Inventaire du {{ format(parseJSON(board.period), "dd/MM/yyyy") }}
        </h3>
        <p class="text-sm text-primary" v-if="board.status === 'sent'">
          Envoyé le {{ format(parseJSON(board.sentAt), "dd/MM/yyyy") }} à
          {{ format(parseJSON(board.sentAt), "HH:mm") }}
        </p>
        <p class="text-sm text-success" v-if="board.status === 'validated'">
          Validé le {{ format(parseJSON(board.validatedAt), "dd/MM/yyyy") }} à
          {{ format(parseJSON(board.validatedAt), "HH:mm") }}
        </p>
      </div>
    </template>
    <DataTable
      :value="rows"
      :scrollable="true"
      scrollHeight="flex"
      stripedRows
      v-model:filters="filters"
      filterDisplay="row"
      rowGroupMode="subheader"
      groupRowsBy="category"
      :expandableRowGroups="true"
      v-model:expandedRowGroups="expandedRowGroups"
    >
      <template #groupheader="slotProps">
        <div class="flex justify-content-between w-full font-semibold">
          <div>{{ slotProps.data.category }}</div>
          <div>{{ calcCategoryValueTotal(slotProps.data.category) }} €</div>
        </div>
      </template>
      <Column :showFilterMenu="false" filterField="name" header="Produit">
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            placeholder="Rechercher"
          />
        </template>
        <template #body="{ data }">
          <div class="flex align-items-center">
            <span>{{ data.name }}</span>
            <span class="text-sm text-primary font-italic ml-1">{{
              data.capacity
            }}</span>
          </div>
        </template>
      </Column>
      <Column
        :showFilterMenu="false"
        filterField="total"
        header="Quantité"
        style="max-width: 150px; justify-content: center"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputNumber
            v-model="filterModel.value"
            showButtons
            buttonLayout="horizontal"
            decrementButtonClass="p-button-text p-button-danger"
            incrementButtonClass="p-button-text p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            @input="filterCallback()"
            :min="0"
          />
        </template>
        <template #body="{ data }">
          <strong
            :class="[data.total > 0 ? 'text-primary' : 'text-secondary']"
            >{{ Math.round(data.total * 100) / 100 }}</strong
          >
        </template>
      </Column>
      <Column
        :showFilterMenu="false"
        header="Valorisation"
        class="justify-content-end"
      >
        <template #body="{ data }">
          <span>{{ data.value }} €</span>
        </template>
      </Column>
    </DataTable>
  </Dialog>
</template>

<script>
import { computed, onBeforeMount, ref, watch } from "vue";
import useDatabase from "../../composables/useDatabase";
import { FilterMatchMode } from "primevue/api";
import { format, parseJSON } from "date-fns";
import { projectDatabase } from "@/firebase/config";

export default {
  props: ["modelValue", "data"],
  setup(props, { emit }) {
    const state = computed({
      get: () => {
        return !!props.modelValue;
      },
      set: (value) => {
        if (!value) {
          emit("update:modelValue", undefined);
        }
      },
    });
    const expandedRowGroups = ref();

    const articles = ref([]);
    const easilysArticles = ref([]);
    const centers = ref([]);
    const categories = ref([]);
    const easilysCategories = ref([]);

    onBeforeMount(async () => {
      categories.value = (
        await projectDatabase.ref("mercurial/categories").get()
      ).val();
      easilysCategories.value = (
        await projectDatabase.ref("mercurial-easilys/categories").get()
      ).val();
      articles.value = (
        await projectDatabase.ref("mercurial/articles").get()
      ).val();
      easilysArticles.value = (
        await projectDatabase.ref("mercurial-easilys/articles").get()
      ).val();
      centers.value = (await projectDatabase.ref("centers").get()).val();
    });

    const board = ref(undefined);
    const pending = ref(true);

    watch(state, () => {
      if (state.value) {
        board.value = useDatabase(`boards`).getOne(
          props.modelValue.id
        ).doc.value;
        pending.value = false;
      }
    });

    const calcCategoryValueTotal = (category) => {
      let total = [];
      const res = rows.value.filter((r) => r.category === category);
      res.forEach((r) => {
        total.push(r.value);
      });
      return Math.round(total.reduce((sum, n) => sum + n) * 100) / 100;
    };

    const rows = computed(() => {
      let arr = [];
      let obj;
      if (board.value.status === "sent") {
        obj = board.value.rows;
      } else if (board.value.status === "validated") {
        obj = centers.value[board.value.center].rows;
      }
      const isEasilys = !!board.value.easilys;
      const articlesData = isEasilys ? easilysArticles : articles;
      const categoriesData = isEasilys ? easilysCategories : categories;

      Object.entries(obj).forEach((row) => {
        const article = articlesData.value[row[0]];

        if (!article) return;

        arr.push({
          id: row[0],
          category: categoriesData.value
            ? categoriesData.value[article.category].name
            : "",
          name: article?.name || `${row[0]}`,
          capacity: `${article?.capacity * 100}cl` || null,
          total: row[1].total,
          value: article.rates
            ? Math.round(article.rates.t0 * row[1].total * 100) / 100
            : 0,
        });
      });
      return arr;
    });

    const filters = ref({
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      total: { value: 0, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
    });

    return {
      state,
      board,
      pending,
      rows,
      filters,
      format,
      parseJSON,
      centers,
      expandedRowGroups,
      calcCategoryValueTotal,
    };
  },
};
</script>

<style lang="scss">
body > div.p-dialog-mask.p-component-overlay > div > {
  div.p-dialog-content {
    padding: 0;
  }
  div.p-dialog-header {
    align-items: start;
    padding-bottom: 0;
  }
}
</style>
